import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Apple from '@alltrails/shared/icons/Apple';
import Google from '@alltrails/shared/icons/Google';
import { COLOR_NEUTRAL_WHITE } from '@alltrails/shared/denali/tokens';
import useGoogleClientId from 'hooks/useGoogleClientId';
import FacebookButton from 'components/FacebookButton';
import useFacebookAppId from 'hooks/useFacebookAppId';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import * as styles from './AltLoginsForm.module.scss';

type Props = {
  appleLoginHandler: (response: any) => void;
  facebookLoginHandler: (response: ReactFacebookLoginInfo) => void;
  googleLoginHandler: (response: GoogleLoginResponse) => void;
};

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=1571%3A79911
export default function AltLoginsForm({ appleLoginHandler, facebookLoginHandler, googleLoginHandler }: Props) {
  const facebookAppId = useFacebookAppId();
  const googleClientId = useGoogleClientId();

  return (
    <div className={styles.content}>
      <GoogleLogin
        clientId={googleClientId}
        render={renderProps => (
          <button className={styles.google} data-testid="loginWithGoogle" onClick={renderProps.onClick} type="button">
            <Google />
            <span className={styles.text}>
              <FormattedMessage defaultMessage="Continue with Google" />
            </span>
          </button>
        )}
        buttonText="Login"
        onSuccess={googleLoginHandler}
        // don't send onFailure to Bugsnag, these are expected events like popup_closed_by_user
        cookiePolicy="single_host_origin"
      />
      <FacebookLogin
        disableMobileRedirect
        appId={facebookAppId}
        callback={(userInfo: ReactFacebookLoginInfo) => {
          // user closed or clicked Cancel on the popup before logging in
          if (!userInfo.accessToken) {
            return;
          }

          facebookLoginHandler(userInfo);
        }}
        scope="email,user_friends,user_location"
        render={(renderProps: { onClick: () => void }) => (
          <FacebookButton onClick={renderProps.onClick}>
            <FormattedMessage defaultMessage="Continue with Facebook" />
          </FacebookButton>
        )}
      />
      <button className={styles.apple} data-testid="loginWithApple" onClick={appleLoginHandler} type="button">
        <Apple color={COLOR_NEUTRAL_WHITE} />
        <span className={styles.text}>
          <FormattedMessage defaultMessage="Continue with Apple" />
        </span>
      </button>
    </div>
  );
}
