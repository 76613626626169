import Link from '@alltrails/shared/denali/components/Link';
import Typography from '@alltrails/shared/denali/components/Typography';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import { ReactNode } from 'react';
import Expandable from '../../../shared/Expandable';
import * as styles from './FrequentlyAskedQuestions.module.scss';

const questions = [
  {
    answer: (
      <FormattedMessage defaultMessage="AllTrails gift subscriptions are delivered by email. They contain a redemption code and link to redeem on the web. When redeemed, the AllTrails+ subscription will be applied to your account." />
    ),
    question: <FormattedMessage defaultMessage="How do I redeem my subscription?" />
  },
  {
    answer: (
      <FormattedMessage defaultMessage="Yes! If your recipient has a subscription directly with AllTrails, they can redeem your gift now and it will be applied at their next renewal. If their current subscription is through Google Play or the Apple App Store, they'll need to let it expire or cancel before redeeming (unfortunately, gift subscriptions don’t work in the app stores). Luckily, they have 24 months to use the gift subscription before it expires." />
    ),
    question: <FormattedMessage defaultMessage="Can I give a subscription to someone who already has a AllTrails+ account?" />
  },
  {
    answer: <FormattedMessage defaultMessage="Absolutely. We'll send you an email notification as soon as the gift email is opened." />,
    question: <FormattedMessage defaultMessage="Will I know if my recipient receives their gift?" />
  },
  {
    answer: (
      <FormattedMessage
        defaultMessage="We can help! Please send your order confirmation number to <a>support@alltrails.com</a> and our customer support team will get to the bottom of it."
        values={{
          a: (chunks: ReactNode) => <a href={`mailto:${chunks}`}>{chunks}</a>
        }}
      />
    ),
    question: <FormattedMessage defaultMessage="The subscription I sent wasn’t received?" />
  },
  {
    answer: (
      <FormattedMessage defaultMessage="Yes, your recipient will have 24 months to redeem their subscription from the moment they receive the gift. Once redeemed, the code is good for the length of the subscription that was gifted. For example, if you gift someone a year subscription, that year begins whenever they redeem it." />
    ),
    question: <FormattedMessage defaultMessage="Is there an expiration date on the subscription?" />
  },
  {
    answer: (
      <FormattedMessage defaultMessage="If you don't want your gift delivered by email, you'll have the option to print instead. Then you can deliver your gift by hand or by mail and the PDF will include all the information your recipient needs to redeem it." />
    ),
    question: <FormattedMessage defaultMessage="Can I buy a physical gift subscription?" />
  }
];

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=586%3A30927
const FrequentlyAskedQuestions = () => {
  const languageRegionCode = useLanguageRegionCode();

  return (
    <section className={styles.container}>
      <Typography component="h3" variant="heading600">
        <FormattedMessage defaultMessage="Frequently asked questions" />
      </Typography>
      <div className={styles.questions}>
        {questions.map((question, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Expandable key={index} title={question.question}>
            <Typography component="div" variant="text200">
              {question.answer}
            </Typography>
          </Expandable>
        ))}
      </div>
      <Typography className={styles.need} color="secondary" component="div" variant="text100">
        <FormattedMessage
          defaultMessage="Need help? Ask at <a>support@alltrails.com</a>"
          values={{
            // eslint-disable-next-line react/no-unstable-nested-components
            a: (chunks: ReactNode) => (
              <Link href={`mailto:${chunks}`} variant="secondary">
                {chunks}
              </Link>
            )
          }}
        />
        <br />
        <FormattedMessage
          defaultMessage="or visit our <a>Help Center</a>"
          values={{
            // eslint-disable-next-line react/no-unstable-nested-components
            a: (chunks: ReactNode) => (
              <Link href={getHelpCenterUrl(languageRegionCode)} variant="secondary">
                {chunks}
              </Link>
            )
          }}
        />
      </Typography>
    </section>
  );
};

export default FrequentlyAskedQuestions;
