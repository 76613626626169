import Footer from '@alltrails/shared/components/Footer';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { generateNewLanguageUrl } from '@alltrails/shared/utils/languageSupport';
import useExperiments from 'hooks/useExperiments';
import CustomProvider from '../CustomProvider';

declare global {
  interface Window {
    adaEmbed?: {
      reset: (options: { language: string }) => void;
    };
  }
}

type FooterWrapperProps = {
  wideFooter: boolean;
  noFollowSocials?: boolean | null;
  noReferrerAppLinks?: boolean | null;
};

const FooterWrapper = ({ wideFooter, noFollowSocials = true, noReferrerAppLinks = true }: FooterWrapperProps): JSX.Element => {
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const experiments = useExperiments();
  const hasByotExperiment = experiments['web-discover-byot-phase1-rm']?.value === 'treatment';

  const noFollowSocialsDefaultedToTrue = noFollowSocials === null ? true : noFollowSocials;

  const noReferrerAppLinkDefaultedToTrue = noReferrerAppLinks === null ? true : noReferrerAppLinks;

  return (
    <Footer
      enabledLanguages={__AT_DATA__.enabledLanguages}
      user={user}
      wideFooter={wideFooter}
      handleLanguageChange={(newLanguageRegionCode: LanguageRegionCode) => {
        if (window.adaEmbed) {
          window.adaEmbed.reset({ language: newLanguageRegionCode.split('-')[0] });
        }
        window.location.href = generateNewLanguageUrl(window.location.pathname, newLanguageRegionCode);
      }}
      languageRegionCode={languageRegionCode}
      noFollowSocials={noFollowSocialsDefaultedToTrue}
      noReferrerAppLinks={noReferrerAppLinkDefaultedToTrue}
      irclickid={__AT_DATA__.irclickid}
      hasByotExperiment={hasByotExperiment}
    />
  );
};

export default function Wrapper({ wideFooter, noFollowSocials, noReferrerAppLinks }: FooterWrapperProps) {
  return (
    <CustomProvider>
      <FooterWrapper wideFooter={wideFooter} noFollowSocials={noFollowSocials} noReferrerAppLinks={noReferrerAppLinks} />
    </CustomProvider>
  );
}
