import onClickLinkAppendUrlParams from '@alltrails/shared/utils/onClickLinkAppendUrlParams';
import * as styles from './styles/styles.module.scss';

export type ActivityRiverItemProps = {
  url: string;
  imgSrc: string;
  title: string;
  activityUid: string;
};

const ActivityRiverItem = ({ url, activityUid, imgSrc, title = '' }: ActivityRiverItemProps): JSX.Element => (
  <div className={styles.container} data-testid={title}>
    <a href={url} onClick={e => onClickLinkAppendUrlParams(e, { 'a[]': activityUid })}>
      <img src={imgSrc} className={styles.image} alt={title} loading="lazy" />
      <div className={styles.title}>{title}</div>
    </a>
  </div>
);

export default ActivityRiverItem;
