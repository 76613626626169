/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { useState, useRef, useMemo, Key } from 'react';
import Slick from 'react-slick';
import Button from '@alltrails/shared/denali/components/Button';
import { FormattedMessage, defineMessages, useIntl } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import DesktopFeatures from './DesktopFeatures';
import * as styles from './ExploreAndDiscover.module.scss';
import { wrapUrlSafe } from '../../../utils/language_support_util';
import { DESKTOP_BREAKPOINT } from '../../../utils/constants/breakpoints';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const EXPLORE_AND_DISCOVER_STRINGS = defineMessages({
  SEARCH_DISCOVER_NAV: {
    defaultMessage: 'Search & discover'
  },
  NAVIGATE_TRACK_NAV: {
    defaultMessage: 'Navigate & track'
  },
  REMEMBER_INSPIRE_NAV: {
    defaultMessage: 'Remember & inspire'
  }
});

type Props = {
  windowWidth: number;
};

const ExploreAndDiscover = ({ windowWidth }: Props): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const intl = useIntl();

  const {
    formattedDefaultMessages: { SEARCH_DISCOVER_NAV, NAVIGATE_TRACK_NAV, REMEMBER_INSPIRE_NAV }
  } = useFormatMessage(EXPLORE_AND_DISCOVER_STRINGS);
  const isDesktop = useMemo(() => windowWidth && windowWidth >= DESKTOP_BREAKPOINT, [windowWidth]);

  const SLIDE_NAVS = [SEARCH_DISCOVER_NAV, NAVIGATE_TRACK_NAV, REMEMBER_INSPIRE_NAV];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const carouselRef = useRef<Slick>();

  const navText = SLIDE_NAVS[currentSlide];
  const carouselLength = 3;

  const slickSettings = {
    afterChange: (index: number) => {
      setCurrentSlide(index);
      setOpacity(1);
    },
    arrows: false,
    beforeChange: () => {
      setOpacity(0);
    }
  };

  const renderDots = () => (
    <div className={styles.dots}>
      {[...Array(carouselLength)].map((_, i) => (
        <button
          key={SLIDE_NAVS[i]}
          type="button"
          aria-label={`${SLIDE_NAVS[i]}`}
          className={`${styles.dot} ${currentSlide === i ? styles.active : ''}`}
          onClick={() => {
            carouselRef?.current?.slickGoTo(i);
          }}
        />
      ))}
    </div>
  );

  // https://www.figma.com/file/s0f8OAOdnfNgsp1CKDcDIe/P_Homepage-Updates?type=design&node-id=2374-5089
  // https://www.figma.com/file/s0f8OAOdnfNgsp1CKDcDIe/P_Homepage-Updates?type=design&node-id=3005-4434&t=aw9ljcnLFubjKRHr-4
  const slides = [
    {
      alt: intl.formatMessage({
        defaultMessage:
          'Trail reviews and ratings are displayed on a phone screen. The trail displayed has an average rating of 4.7 out of 5 stars from 4616 reviews. A 5-star written review reads, “Nice flat hike surrounded by beautiful scenery and mountains. There’s not much shade, so wear a hat and sunscreen.”'
      }),
      avif: require(`./search-${languageRegionCode.toLowerCase()}.avif`),
      png: require(`./search-${languageRegionCode.toLowerCase()}.png`),
      text: (
        <FormattedMessage defaultMessage="All our trails are verified by experts and reviewed by our global community of adventurers like you." />
      ),
      title: <FormattedMessage defaultMessage="Pick the right trail for your day" />
    },
    {
      alt: intl.formatMessage({
        defaultMessage:
          'An active navigation session is displayed on a phone screen. The trail map shows a blue arrow to indicate the person’s GPS location. A green line in front of the arrow shows where the person is headed and a blue line behind the arrow indicates where they’ve been. Below the map, tracked time, distance, and elevation gain are displayed, along with a button to pause navigation.'
      }),
      avif: require(`./navigate-${languageRegionCode.toLowerCase()}.avif`),
      png: require(`./navigate-${languageRegionCode.toLowerCase()}.png`),
      text: (
        <FormattedMessage defaultMessage="Out on the trail, you can keep an eye on your planned route, along with elevation changes and waypoints." />
      ),
      title: <FormattedMessage defaultMessage="Navigate & track" />
    },
    {
      alt: intl.formatMessage({
        defaultMessage:
          'A collection of saved trail lists is displayed on a phone screen. List titles include: “My favorites,” “My maps,” “Top waterfall trails,” “Dog hikes,” and “Dream trails.” At the top is a button to create a new list.'
      }),
      avif: require(`./remember-${languageRegionCode.toLowerCase()}.avif`),
      png: require(`./remember-${languageRegionCode.toLowerCase()}.png`),
      text: <FormattedMessage defaultMessage="Log all your trails in one place, and share insights to inspire and inform fellow adventurers." />,
      title: <FormattedMessage defaultMessage="Keep track of your travels" />
    }
  ];

  const slide = slides[currentSlide];

  const renderMobileBottomContent = () => (
    <div className={styles.bottomContainer}>
      <div className={styles.texture}>
        <div className={styles.carousel}>
          <Slick ref={carouselRef} {...slickSettings}>
            {slides.map((slide, index) => (
              <div className={styles.slideContainer} key={index as Key}>
                <picture>
                  <source srcSet={slide.avif} type="image/avif" />
                  <img alt={slide.alt} className={styles.slideImage} src={slide.png} loading="lazy" />
                </picture>
              </div>
            ))}
          </Slick>
        </div>
      </div>
      {renderDots()}
      <div style={{ opacity }} className={styles.mobileContainer}>
        <h3 className={styles.nav}>{navText}</h3>
        <h2 className={styles.title}>{slide.title}</h2>
        <p className={styles.featureText}>{slide.text}</p>
        <div className={styles.signUpLink}>
          <Button
            text={<FormattedMessage defaultMessage="Sign up" />}
            linkInfo={{ href: wrapUrlSafe('/signup', languageRegionCode) }}
            testId="explore-and-discover-signup-link"
            variant="primary"
          />
        </div>
      </div>
    </div>
  );

  return isDesktop ? <DesktopFeatures slides={slides} /> : renderMobileBottomContent();
};

export default ExploreAndDiscover;
