import Button from '@alltrails/shared/denali/components/Button';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { LanguageSupportUtil } from 'utils/language_support_util';
import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import useUser from 'hooks/useUser';
import { MEDIA_QUERY_SMALL_DOWN } from 'utils/constants/breakpoints';
import logHomepageProUpsellBlockButtonClicked from '@alltrails/analytics/events/logHomepageProUpsellBlockButtonClicked';
import Logo from '@alltrails/shared/denali/components/Logo';
import Check from '@alltrails/shared/icons/Check';
import PlusBadge from '@alltrails/shared/denali/components/PlusBadge';
import * as styles from './PlusUpsell.module.scss';
import mobileSrc from '../PlusUpsellNoAuth/plusUpsellNoAuth-655w.jpg';
import mobileSrcAvif from '../PlusUpsellNoAuth/plusUpsellNoAuth-655w.avif';
import mobileSrcWebp from '../PlusUpsellNoAuth/plusUpsellNoAuth-655w.webp';
import webSrc from '../PlusUpsellNoAuth/plusUpsellNoAuth-1241w.jpg';
import webSrcAvif from '../PlusUpsellNoAuth/plusUpsellNoAuth-1241w.avif';
import webSrcWebp from '../PlusUpsellNoAuth/plusUpsellNoAuth-1241w.webp';

const features = [
  { text: <FormattedMessage defaultMessage="Download offline maps" /> },
  { text: <FormattedMessage defaultMessage="Get alerts for wrong turns" /> },
  { new: true, text: <FormattedMessage defaultMessage="Live share your activities" /> },
  { text: <FormattedMessage defaultMessage="Access 200+ Guides" /> },
  { text: <FormattedMessage defaultMessage="Preview 3D Flyovers" /> }
];

// https://www.figma.com/file/o24ZQP00Um7Ie23xWMwIoL/Sketches?type=design&node-id=108-26124&mode=dev
export default function PlusUpsell() {
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.tableAndImageContainer}>
        <picture>
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={mobileSrcAvif} type="image/avif" />
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={mobileSrcWebp} type="image/webp" />
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={mobileSrc} type="image/jpg" />
          <source srcSet={webSrcAvif} type="image/avif" />
          <source srcSet={webSrcWebp} type="image/webp" />
          <img
            alt={intl.formatMessage({ defaultMessage: 'Hikers walk through a grassy field bordered by pine forest.' })}
            src={webSrc}
            className={styles.image}
            loading="lazy"
          />
        </picture>
        <div className={styles.upsellTable}>
          <Logo className={styles.logo} color="light" size="md" variant="plus-logomark" />
          <div className={styles.headingTable}>
            <h2 className={styles.title}>
              <FormattedMessage defaultMessage="More tools for more adventures" />
            </h2>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage defaultMessage="Features" />
                  </th>
                  <th>
                    <FormattedMessage defaultMessage="Free" />
                  </th>
                  <th className={styles.plus}>
                    <span>
                      <FormattedMessage defaultMessage="AllTrails+" />
                    </span>
                    <PlusBadge size="md" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {features.map((feature, index) => (
                  <tr key={index}>
                    <td>
                      {feature.text}
                      {feature.new && (
                        <span className={styles.newFeature}>
                          <FormattedMessage defaultMessage="New" />
                        </span>
                      )}
                    </td>
                    <td />
                    <td>
                      <Check color="var(--color-plus-300)" size="md" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <Button
              allowWrap // mobile pt-br
              className={styles.button}
              text={
                !user || user.isPromoEligible ? (
                  <FormattedMessage defaultMessage="Try AllTrails+ free" />
                ) : (
                  <FormattedMessage defaultMessage="Buy AllTrails+" />
                )
              }
              linkInfo={{ href: LanguageSupportUtil.wrapUrlSafe('/plus', languageRegionCode) }}
              onClick={() => logHomepageProUpsellBlockButtonClicked()}
              testId="plus-upsell-try-or-buy-plus"
              variant="accent"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
