import AuthenticationType from '@alltrails/analytics/enums/AuthenticationType';
import DeferredDeepLinkType from '@alltrails/analytics/enums/DeferredDeepLinkType';
import logSignUpSuccess from '@alltrails/analytics/events/logSignUpSuccess';
import logLogInSuccess from '@alltrails/analytics/events/logLogInSuccess';
import { trackTinuitiRegistration } from '@alltrails/shared/utils/tinuitiUtils';
import { trackTinuitiOOHRegistration } from 'utils/TinuitiOOHPixel';

// Please see OAuthHandlers for handling apple, facebook, google, etc. login/signups

// TODO: Eventually consolidate some of this event tracking logic on backend and
// move this tracking there instead to be more accurate, improve client performance,
// and avoid GDPR cookie gotchas.
// Note: Sending user_ids client-side is still important for merging web users with mobile users.
const authSuccessCallback = (data: any, authType: AuthenticationType, isCollabListInvite?: boolean) => {
  const { is_sign_up, user } = data;
  if (is_sign_up) {
    if (window.amplitude) {
      // TODO: require as argument + pass-in user.id to logSignupSuccess instead of setting outside
      window.amplitude.setUserId(user?.id);
    }

    window.Sprig?.('identifyAndTrack', {
      eventName: 'Sign_Up_Success',
      userId: user?.id
    });

    if (isCollabListInvite) {
      logSignUpSuccess({ authentication_type: authType, deferred_deep_link_type: DeferredDeepLinkType.CollaborativeList });
    } else {
      logSignUpSuccess({ authentication_type: authType });
    }

    // GA
    if (typeof gtag !== 'undefined') {
      // Set GA user id
      // https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id
      gtag('config', window.ga4_measure_id, {
        user_id: user.id
      });
      // GA UA
      let pagePart;
      if (authType === AuthenticationType.Email) {
        pagePart = '/';
      } else if (authType === AuthenticationType.GoogleOneTap) {
        pagePart = '/google/';
      } else {
        pagePart = `/${authType}/`;
      }
      gtag('event', 'page_view', { page_path: `/register${pagePart}success` });
      // GA4
      // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#sign_up
      gtag('event', 'sign_up', { method: authType });
    }
    // FB Pixel
    // https://developers.facebook.com/docs/meta-pixel/reference#:~:text=ADD_TO_WISHLIST-,CompleteRegistration,-When%20a%20registration
    // Check for presence of fbq in case of content blockers
    if (typeof fbq !== 'undefined') {
      fbq('init', window.fb_pixel_id, {
        external_id: user.id
      });
      fbq('track', 'CompleteRegistration', { content_name: `signup - ${authType}` });
    }

    // TikTok Pixel
    if (typeof ttq !== 'undefined') {
      ttq.identify({
        external_id: user.id
      });

      ttq.track('CompleteRegistration');
    }

    // Tinuiti Pixel
    trackTinuitiRegistration(user.id.toString());

    // Tinuiti OOH Pixel
    trackTinuitiOOHRegistration();
  } else {
    if (window.amplitude) {
      // TODO: require as argument + pass-in user.id to logLoginSuccess instead of setting outside
      window.amplitude.setUserId(user?.id);
    }
    if (isCollabListInvite) {
      logLogInSuccess({
        authentication_type: authType,
        deferred_deep_link_type: DeferredDeepLinkType.CollaborativeList
      });
    } else {
      logLogInSuccess({ authentication_type: authType });
    }
  }
};

export default authSuccessCallback;
