import NavigationHeader from 'components/NavigationHeader';
import CustomProvider from 'components/CustomProvider';
import { ComponentProps } from 'react';

type Props = ComponentProps<typeof NavigationHeader>;

function NavBar(props: Props) {
  return (
    <CustomProvider>
      <NavigationHeader {...props} />
    </CustomProvider>
  );
}

export default NavBar;
