import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import Logo from '@alltrails/shared/denali/components/Logo';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import logWebHeaderAllTrailsLogoTapped from '@alltrails/analytics/events/logWebHeaderAllTrailsLogoTapped';
import useUser from 'hooks/useUser';
import * as styles from './styles/styles.module.scss';
import { HeaderBackground } from './types';
import useIsMobileSizedScreen from './useIsMobileSizedScreen';

type LogoLinkProps = {
  background?: HeaderBackground;
};

const LogoLink = ({ background }: LogoLinkProps) => {
  const languageRegionCode = useLanguageRegionCode();
  const isMobile = useIsMobileSizedScreen();
  const user = useUser();

  return (
    <div className={styles.focusContainer}>
      <a
        className={styles.logoLink}
        data-testid="header-logo"
        href={wrapUrlSafe('/', languageRegionCode)}
        onClick={() => {
          logWebHeaderAllTrailsLogoTapped();
        }}
      >
        <Logo color={background === 'transparentInverted' ? 'light' : 'dark'} size="sm" variant={user || isMobile ? 'symbol' : 'logomark'} />
      </a>
    </div>
  );
};
export default LogoLink;
