import onClickLinkAppendUrlParams, { splitUrl } from '@alltrails/shared/utils/onClickLinkAppendUrlParams';
import * as styles from './styles/styles.module.scss';

type Props = {
  url: string;
  title: string;
  defaultSrc: string;
  srcWithoutExt: string;
};

const ActivitySuitabilityImage = ({ url, title, defaultSrc, srcWithoutExt }: Props): JSX.Element => {
  // Append URL params on click to avoid redundant crawls to explore
  const [baseUrl, urlParams] = splitUrl(url);

  return (
    <a href={baseUrl} onClick={e => onClickLinkAppendUrlParams(e, urlParams)}>
      <div className={styles.container}>
        <div className={styles.opacCover} />
        <div className={styles.title}>{title}</div>
        <picture>
          <source srcSet={`${srcWithoutExt}@2x.avif`} type="image/avif" />
          <source srcSet={`${srcWithoutExt}@2x.webp`} type="image/webp" />
          <img className={styles.img} src={defaultSrc} alt="" loading="lazy" />
        </picture>
      </div>
    </a>
  );
};

export default ActivitySuitabilityImage;
