import { FormattedMessage } from '@alltrails/shared/react-intl';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { anonymizeUser } from '@alltrails/analytics/amplitudeHelpers';
import LoggedOutReason from '@alltrails/analytics/enums/LoggedOutReason';
import logLoggedOut from '@alltrails/analytics/events/logLoggedOut';
import PlusBadge from '@alltrails/shared/denali/components/PlusBadge';
import ArrowExternal from '@alltrails/shared/icons/ArrowExternal';
import Settings from '@alltrails/shared/icons/Settings';
import Help from '@alltrails/shared/icons/Help';
import Logout from '@alltrails/shared/icons/Logout';
import logWebHeaderMyAccountDropdownTapped from '@alltrails/analytics/events/logWebHeaderMyAccountDropdownTapped';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import Chevron from '@alltrails/shared/icons/Chevron';
import Avatar from '@alltrails/shared/denali/components/Avatar';
import { fullName } from '@alltrails/shared/utils/formatters/textFormatters';
import { User } from 'types/User';
import { LinkInfo } from '@alltrails/shared/denali/components/DenaliComponentProps';
import { DropdownMenuOption, DropdownMenuTextOption } from '@alltrails/shared/types/dropdownMenu';
import hasPermission from 'utils/hasPermission';
import { Context } from 'types/Context';
import WebHeaderSection from '@alltrails/analytics/enums/WebHeaderSection';
import WebHeaderDropDownLink from '@alltrails/analytics/enums/WebHeaderDropDownLink';
import logWebHeaderDropDownTapped from '@alltrails/analytics/events/logWebHeaderDropDownTapped';
import WebHeaderDropdownItem from '@alltrails/analytics/enums/WebHeaderDropdownItem';
import * as styles from './styles/styles.module.scss';

type HeaderLinkBase = Pick<DropdownMenuTextOption, 'className' | 'leadingIcon' | 'onClick' | 'subtext' | 'title' | 'testId' | 'trailingIcon'> & {
  webHeaderDropDownLink: WebHeaderDropDownLink;
};

export type AuthRequiredHeaderLink = HeaderLinkBase & { requiresAuth: true; getLinkInfo: (user: User) => LinkInfo };

export type BasicHeaderLink = HeaderLinkBase & { requiresAuth?: never; linkInfo: LinkInfo };

export type HeaderLink = AuthRequiredHeaderLink | BasicHeaderLink;

export const getUserLinks = (user: User, languageRegionCode: LanguageRegionCode, includeLeadingIcons = false): HeaderLink[] => {
  const links: HeaderLink[] = [];
  const fullUserName = fullName(user);
  links.push({
    leadingIcon: {
      customElement: <Avatar hasPlus={user.pro} size="sm" testId="header-show-profile-avatar" userId={user.id} userName={fullUserName} />
    },
    linkInfo: { href: wrapUrlSafe(`/members/${user.slug}`, languageRegionCode) },
    subtext: <FormattedMessage defaultMessage="Show profile" />,
    testId: 'header-show-profile',
    title: fullUserName,
    trailingIcon: { icon: { Component: Chevron, orientation: 'right', size: 'sm' } },
    webHeaderDropDownLink: WebHeaderDropDownLink.ShowProfile
  });

  if (!user.pro) {
    links.push({
      className: styles.plusBackground,
      linkInfo: { href: wrapUrlSafe('/plus', languageRegionCode) },
      testId: 'header-try-alltrails-plus',
      title: <FormattedMessage defaultMessage="Try AllTrails+ for free" />,
      trailingIcon: { customElement: <PlusBadge size="md" /> },
      webHeaderDropDownLink: WebHeaderDropDownLink.AtPlus
    });
  }

  links.push(
    {
      leadingIcon: includeLeadingIcons ? { icon: { Component: Settings }, background: true } : undefined,
      linkInfo: { href: wrapUrlSafe('/my/profile/edit', languageRegionCode) },
      testId: 'header-settings',
      title: <FormattedMessage defaultMessage="Settings" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.Settings
    },
    {
      leadingIcon: includeLeadingIcons ? { icon: { Component: Help }, background: true } : undefined,
      linkInfo: { href: getHelpCenterUrl(languageRegionCode) },
      testId: 'header-help-center',
      title: <FormattedMessage defaultMessage="Help center" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.HelpCenter
    },
    {
      leadingIcon: includeLeadingIcons ? { icon: { Component: Logout }, background: true } : undefined,
      linkInfo: { href: wrapUrlSafe('/sign_out', languageRegionCode) },
      testId: 'header-log-out',
      onClick: () => {
        logWebHeaderMyAccountDropdownTapped({ item_name: WebHeaderDropdownItem.Logout, item_position: 5 });
        logLoggedOut({ log_out_reason: LoggedOutReason.Manual });
        window.Sprig?.('logoutUser');
        // Amplitude-specific.
        anonymizeUser();
      },
      title: <FormattedMessage defaultMessage="Log out" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.LogOut
    }
  );

  return links;
};

export const getUserPermissionLinks = (languageRegionCode: LanguageRegionCode, context: Context): DropdownMenuOption[] => {
  const adminHeaderSection: DropdownMenuOption = {
    sectionHeader: <FormattedMessage defaultMessage="Admin" />
  };

  const partnersHeaderSection: DropdownMenuOption = {
    sectionHeader: <FormattedMessage defaultMessage="Partners" />
  };

  const internalToolsLink: DropdownMenuOption = {
    linkInfo: { href: wrapUrlSafe('/manage/search', languageRegionCode) },
    testId: 'header-internal-tools',
    title: <FormattedMessage defaultMessage="Internal tools" />,
    trailingIcon: { icon: { Component: ArrowExternal, size: 'sm' } }
  };

  const publicLandsPortalLink: DropdownMenuOption = {
    linkInfo: { href: wrapUrlSafe('/portal', languageRegionCode) },
    testId: 'header-public-lands-portal',
    title: <FormattedMessage defaultMessage="Public Lands portal" />,
    trailingIcon: { icon: { Component: ArrowExternal, size: 'sm' } }
  };

  // Until the public_lands:manage role is launched, we need to use the legacy check to see if a user has portal access.
  // Since this is just a temporary patch, just cast the Context type here.
  const hasLegacyPortalAccess = ((context as Context & { groups?: any[] })?.groups?.length || 0) > 0;

  // In the future we may want 'portal:view' or a similar permission that we assign to certain roles
  if (
    hasPermission({ permission: 'areas:manage' }) ||
    hasPermission({ permission: 'locations:manage' }) ||
    hasPermission({ permission: 'public_lands_onboarding:manage' })
  ) {
    return [adminHeaderSection, internalToolsLink, publicLandsPortalLink];
  }
  if (hasPermission({ permission: 'guides:manage' }) || hasPermission({ permission: 'seo_tests:manage' })) {
    return [adminHeaderSection, internalToolsLink];
  }
  if (hasPermission({ permission: 'public_lands:manage' }) || hasLegacyPortalAccess) {
    return [partnersHeaderSection, publicLandsPortalLink];
  }

  return [];
};

export const getHeaderLinkOnClickWithAnalytics =
  (index: number, webHeaderDropDownLink: WebHeaderDropDownLink, webHeaderSection: WebHeaderSection, onClick?: () => void) => () => {
    logWebHeaderDropDownTapped({ item_name: webHeaderDropDownLink, item_position: index, web_header_section: webHeaderSection });
    onClick?.();
  };
