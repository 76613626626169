import { ReactNode } from 'react';
import { useHistory } from '@alltrails/shared/components/ReactRouter';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import Button from '@alltrails/shared/denali/components/Button';
import useWindowWidth from 'hooks/useWindowWidth';
import classNames from 'classnames';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { monthDayYear } from '@alltrails/shared/utils/timeHelpers';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import * as styles from './GiftReview.module.scss';
import GiftCard, { GiftCardVariants } from '../GiftCard';
import { LanguageSupportUtil } from '../../../utils/language_support_util';
import { pushHistory } from '../../../utils/client_side_routing_helpers';
import ShopifyPreview from '../../blocks/ShopifyPreview';
import { DESKTOP_BREAKPOINT } from '../../../utils/constants/breakpoints';

const GiftCardAndMessage = ({ message, background, className }: { message: ReactNode; background: GiftCardVariants; className?: string }) => (
  <div className={classNames(className)}>
    <GiftCard variant={background} className={styles.giftCard} />
    <div className={styles.giftMessage}>{message}</div>
  </div>
);

const Info = ({ label, value }: { label: ReactNode; value: ReactNode }) => (
  <div>
    <span>{label}</span>: <span className={styles.value}>{value}</span>
  </div>
);

type Props = {
  background: GiftCardVariants;
  code: string;
  message?: ReactNode;
  metadata: {
    deliveryDate: any;
  };
  recipientEmail: string;
  recipientName: string;
  isEmail?: boolean;
  showShopifyPreview?: unknown;
  shopifyProducts: {
    images: {
      src: string;
      alt: string;
    }[];
    title: string;
    shopItemUrl: string;
    price: string;
  }[];
};

const GiftReview = ({ background, message, metadata, recipientEmail, recipientName, isEmail, code, shopifyProducts, showShopifyPreview }: Props) => {
  const languageRegionCode = useLanguageRegionCode();
  const windowWidth = useWindowWidth({});

  const history = useHistory();

  const startExploring = () => {
    window.location.href = LanguageSupportUtil.wrapUrlSafe('/explore', languageRegionCode);
  };

  const downloadGiftCard = () => {
    pushHistory(`/gift/preview?${code}`, history, languageRegionCode);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.cardColumn}>
          <GiftCardAndMessage background={background} message={message} />
        </div>
        <div className={styles.metadataColumn}>
          <div className={styles.header}>
            <div>
              <FormattedMessage defaultMessage="Gift subscription purchased." /> <FormattedMessage defaultMessage="Thank you!" />
            </div>
            {windowWidth && windowWidth < DESKTOP_BREAKPOINT && (
              <GiftCardAndMessage background={background} message={message} className={styles.giftAndMessageMobile} />
            )}
          </div>
          <div className={styles.metadata}>
            <Info label={<FormattedMessage defaultMessage="To" />} value={recipientName} />
            {isEmail && <Info label={<FormattedMessage defaultMessage="Email" />} value={recipientEmail} />}
            <Info label={<FormattedMessage defaultMessage="Plan" />} value={<FormattedMessage defaultMessage="Annual plan" />} />
            {isEmail && (
              <Info label={<FormattedMessage defaultMessage="Send date" />} value={monthDayYear(metadata.deliveryDate, languageRegionCode)} />
            )}
            <div className={styles.paragraphEnd}>
              {isEmail ? (
                <>
                  <div className={styles.spacer}>
                    <FormattedMessage defaultMessage="Thank you for giving the gift of AllTrails+. We are in the process of delivering the gift subscription to the recipient. We will inform you when it has been sent." />
                  </div>
                  <div className={styles.spacer}>
                    <FormattedMessage defaultMessage="Check your inbox for a confirmation email." />
                  </div>
                </>
              ) : (
                <div className={styles.spacer}>
                  <FormattedMessage defaultMessage="Check your inbox for a confirmation email. Download the gift subscription below." />
                </div>
              )}
            </div>
            {!isEmail && (
              <Button
                text={<FormattedMessage defaultMessage="Download gift subscription" />}
                className={styles.button}
                onClick={downloadGiftCard}
                testId="gift-review-download-gift-subscription"
                variant="primary"
              />
            )}
            <Button
              text={<FormattedMessage defaultMessage="Buy again" />}
              className={styles.button}
              linkInfo={{ href: wrapUrlSafe('/gift', languageRegionCode) }}
              testId="gift-review-buy-again"
              variant={isEmail ? 'primary' : 'default'}
            />
            <Button
              text={<FormattedMessage defaultMessage="Start exploring!" />}
              className={styles.button}
              onClick={startExploring}
              testId="gift-review-start-exploring"
              variant="default"
            />
          </div>
        </div>
      </div>
      {showShopifyPreview && <ShopifyPreview shopifyProducts={shopifyProducts} windowWidth={windowWidth} />}
    </>
  );
};

GiftReview.defaultProps = {
  isEmail: false,
  message: '',
  showShopifyPreview: false
};

export default GiftReview;
