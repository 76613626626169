import { useEffect, useState } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Coupon from 'types/Coupon';
import CurrencyCode from 'types/CurrencyCode';
import Plan from 'types/Plan';
import logError from 'utils/logError';
import { execute } from 'utils/recaptcha_helpers';
import ProResponse from 'types/ProResponse';
import { ApplePayInstance } from '@recurly/recurly-js';
import { getPriceTotal } from 'utils/PlanUtils';
import * as styles from './ApplePayButton.module.scss';
import { PaymentDisplayText } from '../../Constants';

type Props = {
  coupon?: Coupon;
  currencyCode: CurrencyCode;
  onApplePayToken: ({ apple_pay_token, recaptcha }: { apple_pay_token: string; recaptcha: string }) => void;
  plan: Plan;
  setErrors: (errors: ProResponse['errors']) => void;
  setSubmitting: (submitting: boolean) => void;
  showContinueWith?: boolean;
};

// Desktop: https://app.zeplin.io/project/627c18d6c6189e2a52ce6566/screen/627c1bcf26ba802db57c180b
// Mobile: https://app.zeplin.io/project/627c18d6c6189e2a52ce6566/screen/627c241f824169281f996dc1
export default function ApplePayButton({ coupon, currencyCode, onApplePayToken, plan, setErrors, setSubmitting, showContinueWith = false }: Props) {
  const [applePayInstance, setApplePayInstance] = useState<ApplePayInstance>();
  const [applePayReady, setApplePayReady] = useState(false);

  useEffect(() => {
    // https://github.com/recurly/recurly-js/blob/4e2789fd7e5a8e07e8dcd5a204a25ca92894ba68/lib/recurly/apple-pay/apple-pay.js#L57
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      recurly.configure(__AT_DATA__.recurlyPublicKey);

      const applePay = recurly.ApplePay({
        country: 'US', // this is the merchant's country code, so will remain US
        currency: currencyCode,
        label: PaymentDisplayText,
        total: String(getPriceTotal(plan, coupon))
      });

      applePay.on('error', error => {
        logError(error);
      });

      applePay.on('token', async token => {
        try {
          setSubmitting(true);

          const recaptcha = await execute('userPaymentAction');

          await onApplePayToken({ apple_pay_token: token.id, recaptcha });
        } catch (e) {
          setErrors(e);
          setSubmitting(false);
        }
      });

      applePay.ready(() => {
        setApplePayReady(true);
      });

      setApplePayInstance(applePay);
    }
  }, [currencyCode, coupon, onApplePayToken, plan, setErrors, setSubmitting]);

  return (
    <button
      className={styles.button}
      onClick={() => {
        if (applePayReady) {
          applePayInstance.begin();
        }
      }}
      type="button"
    >
      {showContinueWith && (
        <span className={styles.text}>
          <FormattedMessage defaultMessage="Continue with" />
          &nbsp;
        </span>
      )}
      <span className={styles.logo}>
        <span className={styles.logo} />
      </span>
    </button>
  );
}
