import Router, { Route, Switch } from '@alltrails/shared/components/ReactRouter';
import ApplicationLayout from 'components/ApplicationLayout/ApplicationLayout';
import CustomProvider from 'components/CustomProvider';
import Coupon from 'types/Coupon';
import { Context } from 'types/Context';
import LoginRoute from './LoginRoute';
import RegisterRoute from './RegisterRoute';

type ProRouterProps = {
  context: Context;
  coupon?: Coupon;
};

function ProRouter({ context, coupon }: ProRouterProps) {
  return (
    <Router fullpath={global.__AT_DATA__.pathname}>
      <Switch>
        <Route path="/:locale?/signup">
          <ApplicationLayout context={context}>
            <RegisterRoute coupon={coupon} />
          </ApplicationLayout>
        </Route>
        <Route path="/:locale?/login">
          <ApplicationLayout context={context}>
            <LoginRoute coupon={coupon} />
          </ApplicationLayout>
        </Route>
        <Route path="/:locale?/plus/signup">
          <ApplicationLayout context={context}>
            <RegisterRoute />
          </ApplicationLayout>
        </Route>
      </Switch>
    </Router>
  );
}

export default function Pro(props: ProRouterProps) {
  return (
    <CustomProvider>
      <ProRouter {...props} />
    </CustomProvider>
  );
}
