import logProPurchaseBuyNowClicked from '@alltrails/analytics/events/logProPurchaseBuyNowClicked';
import WebProPurchaseLocation from '@alltrails/analytics/enums/WebProPurchaseLocation';
import Button from '@alltrails/shared/denali/components/Button';
import Link from '@alltrails/shared/denali/components/Link';
import Typography from '@alltrails/shared/denali/components/Typography';
import { useState, ReactNode, ComponentProps, useRef, Suspense } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Coupon from 'types/Coupon';
import CurrencyCode from 'types/CurrencyCode';
import Plan from 'types/Plan';
import ProResponse from 'types/ProResponse';
import { getPurchaseScreenTypePlan } from 'utils/amplitudeHelper';
import { execute } from 'utils/recaptcha_helpers';
import classNames from 'classnames';
import CountryCode from 'types/CountryCode';
import { ApiError } from '@alltrails/shared/api';
import useTokenizedFormValidation from 'components/forms/TokenizedCreditCardForm/hooks/useTokenizedFormValidation';
import type { RecurlyError, TokenPayload } from '@recurly/recurly-js';
import { Elements, RecurlyProvider, useRecurly, UseRecurlyInstance } from '@recurly/react-recurly';
import { NoSsr } from '@material-ui/core';
import * as styles from './Checkout.module.scss';
import CouponCode from './CouponCode';
import PaymentForm from './PaymentForm';

// Desktop: https://app.zeplin.io/project/627c18d6c6189e2a52ce6566/screen/627c1bcf26ba802db57c180b
// Mobile: https://app.zeplin.io/project/627c18d6c6189e2a52ce6566/screen/627c241f824169281f996dc1

type Props = {
  billedHeading?: ReactNode;
  billedSubheading?: ReactNode;
  billedSubheadingRight?: ReactNode;
  billedToday: ReactNode;
  billingCountry: CountryCode;
  buttonText: ReactNode;
  className?: string;
  coupon?: Coupon;
  currencyCode: CurrencyCode;
  finePrint?: ReactNode;
  promoText?: ReactNode;
  heading: ReactNode;
  headingClassName?: string;
  onApplePayToken: ComponentProps<typeof PaymentForm>['onApplePayToken'];
  onGooglePayToken: ComponentProps<typeof PaymentForm>['onGooglePayToken'];
  onPayPalToken: ComponentProps<typeof PaymentForm>['onPayPalToken'];
  plan: Plan;
  setCoupon?: (arg0: Coupon) => void;
  setPlanToAnnual?: () => void;
  subheading?: ReactNode;
  webProPurchaseLocation: WebProPurchaseLocation;
  features: ReactNode;
  onBillingCountryChange: (countryCode: string, response: any) => void;
  onSubmitTokenizedCardForm?: (payload: { cc_token: string; recaptcha: string }) => void;
  reactRecurlyInstance?: UseRecurlyInstance;
};

function CheckoutBase({
  buttonText,
  billedHeading,
  billedToday,
  billedSubheading,
  billingCountry,
  className,
  coupon,
  currencyCode,
  finePrint,
  promoText,
  heading,
  headingClassName,
  onApplePayToken,
  onGooglePayToken,
  onPayPalToken,
  plan,
  subheading,
  billedSubheadingRight,
  setCoupon,
  setPlanToAnnual,
  webProPurchaseLocation,
  features,
  onBillingCountryChange,
  onSubmitTokenizedCardForm,
  reactRecurlyInstance
}: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<ProResponse['errors']>(); // TODO move up
  const formRef = useRef();
  const { validationErrors, setClientValidationErrors, setTokenRequestErrors, hasValidationErrors } = useTokenizedFormValidation();

  const handleTokenizedCardSubmit = async (e: React.KeyboardEvent | React.FormEvent) => {
    e.preventDefault();

    const isGiftCard = coupon?.type === 'GiftCard';

    if (submitting) {
      return;
    }

    // Skip validation for gift cards.
    if (hasValidationErrors() && !isGiftCard) {
      return;
    }

    const onRecurlyToken = async (err: RecurlyError, token: TokenPayload, recaptcha: string) => {
      if (err) {
        setTokenRequestErrors(err);
        setSubmitting(false);
      } else {
        const body = {
          recaptcha,
          cc_token: token.id
        };

        try {
          await onSubmitTokenizedCardForm(body);
        } catch (serverError) {
          if (serverError instanceof ApiError) {
            setErrors(serverError.data);
          } else {
            setErrors(serverError);
          }
          setSubmitting(false);
        }
      }
    };

    let recaptcha: string | null = null;

    try {
      setSubmitting(true);
      recaptcha = await execute('userPaymentAction');

      if (isGiftCard) {
        await onSubmitTokenizedCardForm({ recaptcha, cc_token: null });
        setSubmitting(false);
        return;
      }

      if (recaptcha) {
        reactRecurlyInstance.token(formRef.current, (err, token) => onRecurlyToken(err, token, recaptcha));
      }
    } catch (e) {
      if (e instanceof ApiError) {
        setErrors(e.data);
      } else {
        setErrors(e);
      }
      setSubmitting(false);
    }
  };

  return (
    <form
      ref={formRef}
      className={classNames(styles.content, className)}
      onSubmit={(e: React.KeyboardEvent | React.FormEvent) => {
        handleTokenizedCardSubmit(e);
      }}
    >
      <div className={styles.tryArea}>
        <div className={classNames(styles.heading, headingClassName)}>{heading}</div>
        {subheading && (
          <div className={styles.subheading}>
            <Typography variant="text100">{subheading}</Typography>
          </div>
        )}
        {features}
      </div>
      <div className={styles.billedCopyright}>
        <div className={styles.billedArea}>
          <div className={styles.billed}>
            <div>{billedHeading || <FormattedMessage defaultMessage="Billed today" />}</div>
            <div>{billedToday}</div>
          </div>
          <div className={styles.hr} />
          {billedSubheading && (
            <div className={styles.after}>
              <div>{billedSubheading}</div>
              <div>{billedSubheadingRight}</div>
            </div>
          )}
          {setCoupon && (
            <div>
              <CouponCode coupon={coupon} setCoupon={setCoupon} setPlanToAnnual={setPlanToAnnual} />
            </div>
          )}
        </div>
        <div className={styles.copyright}>
          <FormattedMessage defaultMessage="{year} AllTrails, LLC All Rights Reserved" values={{ year: new Date().getFullYear() }} />
          <br />
          <Link className={styles.link} href="/privacy" target="_blank" noUnderline>
            <FormattedMessage defaultMessage="Privacy Policy" />
          </Link>{' '}
          |{' '}
          <Link className={styles.link} href="/terms" target="_blank" noUnderline>
            <FormattedMessage defaultMessage="Terms of Service" />
          </Link>
        </div>
      </div>
      <div className={styles.form}>
        <PaymentForm
          billingCountry={billingCountry}
          coupon={coupon}
          currencyCode={currencyCode}
          errors={errors}
          onBillingCountryChange={onBillingCountryChange}
          onApplePayToken={onApplePayToken}
          onGooglePayToken={onGooglePayToken}
          onPayPalToken={onPayPalToken}
          plan={plan}
          setClientValidationErrors={setClientValidationErrors}
          setErrors={setErrors}
          validationErrors={validationErrors}
        />
      </div>
      <div className={styles.button}>
        <Button
          className={styles.button}
          loading={submitting}
          onClick={() => {
            logProPurchaseBuyNowClicked({
              purchase_screen_type_plan: getPurchaseScreenTypePlan(plan, coupon),
              web_purchase_location: webProPurchaseLocation
            });
          }}
          testId="submit"
          text={buttonText}
          type="submit"
          variant="primary"
        />
      </div>
      <div className={styles.fine}>{finePrint}</div>
      <div className={styles.promoText}>{promoText}</div>
    </form>
  );
}

const TokenizedCheckoutBase = (props: Props) => {
  // useRecurly automatically contains a reference to Recurly Elements,
  // which is needed when generating a token with `reactRecurlyInstance.token()`.
  // It can only be used as a child of RecurlyProvider.
  const recurlyReactInstance = useRecurly();
  return <CheckoutBase reactRecurlyInstance={recurlyReactInstance} {...props} />;
};

const CheckoutBaseProvider = (props: Props) => (
  <NoSsr>
    <Suspense fallback={null}>
      {/* RecurlyProvider can't be server rendered */}
      <RecurlyProvider publicKey={__AT_DATA__.recurlyPublicKey}>
        <Elements>
          <TokenizedCheckoutBase {...props} />
        </Elements>
      </RecurlyProvider>
    </Suspense>
  </NoSsr>
);

export default CheckoutBaseProvider;
