import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import logProPurchaseGiveBackButtonClicked from '@alltrails/analytics/events/logProPurchaseGiveBackButtonClicked';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { LanguageSupportUtil } from 'utils/language_support_util';
import * as styles from './styles/styles.module.scss';
import OnePercentModule from './OnePercentModule';
import oneTreeSrc from './one-tree.svg';
import onePercentSrc from './one-percent.svg';
import noTraceSrc from './no-trace.svg';

const messages = defineMessages({
  givePlusAndGiveBack: {
    defaultMessage: 'Give AllTrails+ and give back'
  },
  getPlusAndGiveBack: {
    defaultMessage: 'Get AllTrails+ and give back'
  }
});

const OnePercentBlock = () => {
  const {
    formattedDefaultMessages: { givePlusAndGiveBack, getPlusAndGiveBack }
  } = useFormatMessage(messages);

  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.modulesContainer}>
          <OnePercentModule
            iconSrc={onePercentSrc}
            title={<FormattedMessage defaultMessage="1% for the Planet" />}
            text={<FormattedMessage defaultMessage="A portion of every AllTrails+ membership goes to protecting the wild places we cherish." />}
          />
          <OnePercentModule
            iconSrc={oneTreeSrc}
            title={<FormattedMessage defaultMessage="10,000 trees (and counting)" />}
            text={
              <FormattedMessage defaultMessage="From new member invites to employee anniversaries, we celebrate by giving to One Tree Planted." />
            }
          />
          <OnePercentModule
            iconSrc={noTraceSrc}
            title={<FormattedMessage defaultMessage="No trace on the trail" />}
            text={<FormattedMessage defaultMessage="As a Leave No Trace partner, we’re committed to keeping outdoor spaces clean, safe, and kind." />}
          />
        </div>
        <Button
          text={user?.pro ? givePlusAndGiveBack : getPlusAndGiveBack}
          linkInfo={{
            href: LanguageSupportUtil.wrapUrlSafe(user?.pro ? '/gift' : '/plus', languageRegionCode)
          }}
          onClick={() => logProPurchaseGiveBackButtonClicked()}
          testId="get-alltrails+-and-give-back"
          variant="accent"
          allowWrap
        />
      </div>
    </div>
  );
};

export default OnePercentBlock;
