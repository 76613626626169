import { useIntl } from '@alltrails/shared/react-intl';
import { AlgoliaSearchBox } from '@alltrails/modules/AlgoliaSearch';
import '@alltrails/modules/AlgoliaSearch/index.css';
import SearchOrigin from '@alltrails/analytics/enums/SearchOrigin';
import * as styles from './styles/styles.module.scss';

type SearchBoxProps = {
  placeholder?: string;
};

const SearchBox = ({ placeholder }: SearchBoxProps) => {
  const intl = useIntl();

  return (
    <div className={styles.searchBox}>
      <AlgoliaSearchBox
        clearOnSelect
        configs={global.__AT_DATA__.algoliaConfig}
        dropdownVariant="enveloping"
        navigateOnSelect
        onResultSelect={() => {
          // Do nothing
        }}
        placeholder={placeholder || intl.formatMessage({ defaultMessage: 'Search' })}
        searchOrigin={SearchOrigin.GlobalNav}
        searchTypes="all"
        testId="header-search"
        variant="subtle"
      />
    </div>
  );
};
export default SearchBox;
