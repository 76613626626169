import ApplicationLayout from 'components/ApplicationLayout';
import CustomProvider from 'components/CustomProvider';
import LoginForm from 'components/LoginForm';
import LoginSignupLayout from 'components/LoginSignupLayout';
import { Context } from 'types/Context';

type Props = {
  context: Context;
  returnTo?: string;
};

export default function Login({ context, returnTo }: Props) {
  return (
    <CustomProvider>
      <ApplicationLayout context={context}>
        <LoginSignupLayout>
          {/* we could add react router here for the signup page */}
          <LoginForm returnTo={returnTo} />
        </LoginSignupLayout>
      </ApplicationLayout>
    </CustomProvider>
  );
}
