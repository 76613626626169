import { FormEvent, useState, useEffect, useCallback, ReactNode } from 'react';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import useUser from 'hooks/useUser';
import Button from '@alltrails/shared/denali/components/Button';
import Link from '@alltrails/shared/denali/components/Link';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import CurrencyCode from 'types/CurrencyCode';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { getAvailablePlans } from 'api/Purchases';
import { post } from '@alltrails/shared/api';
import useIpCountryCode from 'hooks/useIpCountryCode';
import CountryCode from 'types/CountryCode';
import { getPriceTotal } from 'utils/PlanUtils';
import logError from 'utils/logError';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';
import BillingCountrySelector, { isBillingCountryEu } from '../../BillingCountrySelector';
import * as styles from './styles/styles.module.scss';

const messages = defineMessages({
  ERR_TRY_AGAIN: { defaultMessage: 'Error, please try again' },
  ONCE_YOU_REDEEM: {
    defaultMessage:
      'Once you redeem the gift subscription, your account will be updated and you can start enjoying the benefits of AllTrails+. Your subscription begins on the redemption date and will be active for the length of the plan that was purchased.'
  },
  REDEEM: { defaultMessage: 'Redeem' },
  REDEMPTION_CODE: { defaultMessage: 'Redemption code' }
});

type RedemptionFormProps = {
  couponCode?: string;
  initialCurrencyCode?: CurrencyCode;
};

type RedeemRequestParams = {
  gift_id: string;
  currency: CurrencyCode;
  billing_country?: CountryCode;
};

const RedemptionForm = ({ couponCode = null, initialCurrencyCode = 'USD' }: RedemptionFormProps) => {
  const {
    formattedDefaultMessages: { ERR_TRY_AGAIN, ONCE_YOU_REDEEM, REDEEM, REDEMPTION_CODE }
  } = useFormatMessage(messages);
  const user = useUser();
  const [code, setCode] = useState(couponCode || '');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [mobileSubActiveError, setMobileSubActiveError] = useState(false);
  const languageRegionCode = useLanguageRegionCode();
  const ipCountryCode = useIpCountryCode();
  const [billingCountry, setBillingCountry] = useState<CountryCode>(ipCountryCode);
  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>(initialCurrencyCode);
  const [renewalPrice, setRenewalPrice] = useState(null);
  const formatter = useCurrencyFormatter(currencyCode);

  const handleBillingCountryChange = useCallback(async () => {
    try {
      const response = await getAvailablePlans(billingCountry as CountryCode);
      setCurrencyCode(response.currencyCode);
      setRenewalPrice(getPriceTotal(response.nonTrial));
    } catch (e) {
      logError(e);
    }
  }, [billingCountry]);

  useEffect(() => {
    handleBillingCountryChange();
  }, [billingCountry, handleBillingCountryChange]);

  function onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (errorMsg) {
      setErrorMsg(null);
    }

    if (mobileSubActiveError) {
      setMobileSubActiveError(false);
    }

    const { value } = e.target;
    setCode(value);
  }

  const redeemGiftCode = () => {
    const params = {
      gift_id: code,
      currency: currencyCode
    } as RedeemRequestParams;

    if (billingCountry !== ipCountryCode) {
      params.billing_country = billingCountry;
    }

    return post(`/api/alltrails/gift/${code}/redeem`, params);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!code) {
      setErrorMsg(ERR_TRY_AGAIN);
      return;
    }
    setLoading(true);

    try {
      await redeemGiftCode();
      window.location.href = '/plus/welcome';
    } catch (err) {
      const errorData = err?.data?.errors?.[0];
      if (errorData && errorData?.code === 'gift_mobile_sub_active') {
        setMobileSubActiveError(true);
      } else {
        const message = errorData?.message || ERR_TRY_AGAIN;
        setErrorMsg(message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChangeBillingCountry = async (billingCountryCode: CountryCode) => {
    setBillingCountry(billingCountryCode);
  };

  const helpCenterLink = (str: ReactNode) => (
    <Link href={getHelpCenterUrl(languageRegionCode, 360052407531)} target="_blank" size="md" testId="mobile-gift-faq">
      {str}
    </Link>
  );

  return (
    <section>
      <form id="redemption-form" onSubmit={onSubmit} className={styles.formContainer}>
        <OutlinedTextField type="text" changeHandler={onChange} value={code} label={REDEMPTION_CODE} errorMessage={errorMsg} />
        {mobileSubActiveError && (
          <div className={styles.giftMobileErrorStyles}>
            <FormattedMessage
              defaultMessage="Your account has a current mobile subscription. Please read our <link>FAQ</link> to learn how to redeem your gift."
              values={{
                link: helpCenterLink
              }}
            />
          </div>
        )}
        <div className={styles.formButtonContainer}>
          <Button
            disabled={!!errorMsg}
            loading={loading}
            testId="redemption-form-redeem"
            text={REDEEM}
            type="submit"
            variant="primary"
            width="full"
          />
        </div>
      </form>
      <div className={styles.formSubText}>
        {ONCE_YOU_REDEEM}
        {!user.pro && (
          <>
            <br />
            <br />
            {renewalPrice && (
              <FormattedMessage
                defaultMessage="You can update your billing information on your profile page to automatically renew your subscription at {price}."
                values={{
                  price: formatter.format(renewalPrice)
                }}
              />
            )}
            {isBillingCountryEu(billingCountry) && (
              <div className={styles.updateBillingContainer}>
                <BillingCountrySelector
                  billingCountryCode={billingCountry}
                  onChangeBillingCountry={handleChangeBillingCountry}
                  className={styles.updateBillingCountry}
                />
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default RedemptionForm;
