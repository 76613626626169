import { ReactNode, useState } from 'react';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import ArrowRec from '@alltrails/shared/icons/ArrowRec';
import logHomepageSignupButtonTapped from '@alltrails/analytics/events/logHomepageSignupButtonTapped';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { wrapEventHandler } from 'utils/handlers';
import Link from '@alltrails/shared/denali/components/Link';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import * as styles from './ExploreAndDiscover.module.scss';
import { LanguageSupportUtil } from '../../../utils/language_support_util';

const DESKTOP_FEATURE_STRINGS = defineMessages({
  SIGNUP: {
    defaultMessage: 'Sign up'
  },
  NAV_NEXT: {
    defaultMessage: 'Navigate next'
  },
  NAV_PREV: {
    defaultMessage: 'Navigate previous'
  }
});

type Props = {
  slides: { alt: string; avif: string; png: string; text: ReactNode; title: ReactNode }[];
};

const DesktopFeatures = ({ slides }: Props) => {
  const languageRegionCode = useLanguageRegionCode();

  const {
    formattedDefaultMessages: { SIGNUP, NAV_NEXT, NAV_PREV }
  } = useFormatMessage(DESKTOP_FEATURE_STRINGS);

  const [currentNav, setCurrentNav] = useState(0);
  const [nextNav, setNextNav] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const slide = slides[currentNav];

  const handleNavClick = (index: number) => {
    setNextNav(index);
    setOpacity(0);
  };

  const handlePrevNextClick = (event: React.MouseEvent, previousOrNext: string) => {
    event.preventDefault();
    let previousOrNextNav = currentNav + (previousOrNext === 'previous' ? -1 : 1);

    if (previousOrNextNav < 0) {
      previousOrNextNav = 2;
    } else if (nextNav >= 2 && previousOrNext === 'next') {
      previousOrNextNav = 0;
    }

    setNextNav(previousOrNextNav);
    setOpacity(0);
  };

  const handleTransitionEnd = () => {
    if (opacity === 0) {
      setOpacity(1);
      setCurrentNav(nextNav);
    }
  };

  return (
    <div className={styles.bottomContainer}>
      <div className={styles.texture}>
        <div className={styles.nav}>
          <div className={styles.upArrow}>
            <IconButton
              icon={{ Component: ArrowRec }}
              linkInfo={{ href: '#previous' }}
              onClick={e => handlePrevNextClick(e, 'previous')}
              testId="desktop-features-previous"
              title={NAV_PREV}
              size="lg"
              variant="transparent"
            />
          </div>
          <Link className={styles.navLink} onClick={wrapEventHandler(() => handleNavClick(0))} size="md">
            <FormattedMessage defaultMessage="Search & discover" />
          </Link>
          <Link className={styles.navLink} onClick={wrapEventHandler(() => handleNavClick(1))} size="md">
            <FormattedMessage defaultMessage="Navigate & track" />
          </Link>
          <Link className={`${styles.navLink} ${styles.last}`} onClick={wrapEventHandler(() => handleNavClick(2))} size="md">
            <FormattedMessage defaultMessage="Remember & inspire" />
          </Link>
          <IconButton
            icon={{ Component: ArrowRec, orientation: 'down' }}
            linkInfo={{ href: '#next' }}
            onClick={e => handlePrevNextClick(e, 'next')}
            testId="desktop-features-next"
            title={NAV_NEXT}
            size="lg"
            variant="transparent"
          />
        </div>
        <div className={styles.screen}>
          <picture>
            <source srcSet={slide.avif} type="image/avif" />
            <img
              style={{ opacity }}
              className={styles.screenImage}
              src={slide.png}
              alt={slide.alt}
              onTransitionEnd={handleTransitionEnd}
              loading="lazy"
            />
          </picture>
        </div>
        <div style={{ opacity }} className={styles.feature}>
          <h2 className={styles.title}>{slide.title}</h2>
          <p className={styles.featureText}>{slide.text}</p>
          <div className={styles.signUpLink}>
            <Button
              text={SIGNUP}
              linkInfo={{ href: LanguageSupportUtil.wrapUrlSafe('/signup', languageRegionCode) }}
              onClick={() => logHomepageSignupButtonTapped()}
              testId="explore-and-discover-sign-up"
              variant="primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopFeatures;
