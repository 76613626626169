import River from 'components/shared/River';
import SocialCard from 'components/blocks/UgcSocialFooter/SocialCard';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { ReactNode, useMemo } from 'react';
import { HOME_PAGE_WIDTH_BREAKPOINT } from 'utils/constants/breakpoints';
import FacebookIcon from '@alltrails/shared/components/socialIcons/FacebookIcon';
import InstagramIcon from '@alltrails/shared/components/socialIcons/InstagramIcon';
import TwitterIcon from '@alltrails/shared/components/socialIcons/TwitterIcon';
import LinkedInIcon from '@alltrails/shared/components/socialIcons/LinkedInIcon';
import TikTokIcon from '@alltrails/shared/components/socialIcons/TikTokIcon';
import * as styles from './styles/styles.module.scss';
import cards from './cards';

const slickSettings = { slidesToShow: 4, lazyLoad: 'progressive', slidesToScroll: 4, speed: 750 };

const ARROW_STYLES = { next: styles.next, previous: styles.previous };

type UgcSocialFooterModuleProps = {
  windowWidth: number;
};

const strong = (chunks: ReactNode) => <strong>{chunks}</strong>;

const UgcSocialFooter = ({ windowWidth }: UgcSocialFooterModuleProps) => {
  const isWideScreen = useMemo(() => windowWidth && windowWidth >= HOME_PAGE_WIDTH_BREAKPOINT, [windowWidth]);

  return (
    <div className={styles.container}>
      <div className={styles.riverContainer}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              <FormattedMessage defaultMessage="Share your next adventure" />
            </div>
            <div className={styles.subTitle}>
              <FormattedMessage
                defaultMessage="Show us how you <strong>#GetOutThere</strong> by tagging us <strong>@AllTrails</strong> for a chance to be featured!"
                values={{ strong }}
              />
            </div>
          </div>

          <div className={styles.links}>
            <InstagramIcon openInNewTab />
            <TikTokIcon openInNewTab />
            <FacebookIcon openInNewTab />
            <TwitterIcon openInNewTab />
            <LinkedInIcon openInNewTab />
          </div>
        </div>
        <River useSlick={isWideScreen} slickSettings={slickSettings} className={styles.riverClass} arrowStyles={ARROW_STYLES}>
          {cards.map(card => (
            <SocialCard {...card} key={card.handle} />
          ))}
        </River>
      </div>
    </div>
  );
};

export default UgcSocialFooter;
