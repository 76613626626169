/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import logSettingsFacebookPageViewed from '@alltrails/analytics/events/logSettingsFacebookPageViewed';
import logSettingsFacebookLoginConfigured from '@alltrails/analytics/events/logSettingsFacebookLoginConfigured';
import logCommunityContactsSynced from '@alltrails/analytics/events/logCommunityContactsSynced';
import FacebookLoginAction from '@alltrails/analytics/enums/FacebookLoginAction';
import CommunityContactsSyncSource from '@alltrails/analytics/enums/CommunityContactsSyncSource';
import CommunityContactsSyncLocation from '@alltrails/analytics/enums/CommunityContactsSyncLocation';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import FacebookSettingsModal from '@alltrails/shared/components/modals/FacebookSettingsModal';
import Button from '@alltrails/shared/denali/components/Button';
import { uploadFacebookTokenToBelay, getFacebookUploadStatus, stopSyncFacebookFriends } from '@alltrails/shared/utils/requests/belayRequests';
import { FacebookStatus } from '@alltrails/shared/types/blazes';
import CustomProvider from 'components/CustomProvider';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import FacebookButton from 'components/FacebookButton';
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';
import useFacebookHandler from 'hooks/useFacebookHandler';
import { ServerCommunicationUtil } from '../../utils/server_communication_util';
import useInterval from '../../hooks/useInterval';

export type FacebookSettingsProps = {
  userId: number;
  facebookAppId: string;
  userFacebookId: string | null;
  unlinkPath: string;
};

const renderFacebookButton = (isLogin: boolean, isSynced: boolean, isSyncing: boolean, renderProps: { onClick: () => void }) => {
  if (isLogin) {
    return (
      <FacebookButton onClick={renderProps.onClick}>
        <FormattedMessage defaultMessage="Log in with Facebook" />
      </FacebookButton>
    );
  }

  if (isSynced) {
    return (
      <Button
        text={<FormattedMessage defaultMessage="Refresh Facebook friends" />}
        onClick={renderProps.onClick}
        disabled={isSyncing}
        testId="Refresh Facebook friends"
      />
    );
  }

  return (
    <Button
      text={<FormattedMessage defaultMessage="Sync Facebook friends" />}
      onClick={renderProps.onClick}
      disabled={isSyncing}
      testId="Sync Facebook friends"
      variant="primary"
    />
  );
};

const FacebookSettings = ({ userId, facebookAppId, userFacebookId, unlinkPath }: FacebookSettingsProps) => {
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [delay, setDelay] = useState<number | null>(2000);
  const [facebookStatus, setFacebookStatus] = useState(FacebookStatus.NotSubmitted);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSynced, setIsSynced] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const facebookHandler = useFacebookHandler();

  const closeModal = () => {
    setShowSettingsModal(false);
  };

  const handleUnlinkClick = () => {
    logSettingsFacebookLoginConfigured({ facebook_login_action: FacebookLoginAction.DisconnectLoginWithFacebook });
    ServerCommunicationUtil.postApiEndpoint(
      unlinkPath,
      {},
      () => setIsLoggedIn(false),
      () => console.error('error when unlinking facebook'),
      () => {}
    );
  };

  const isReactFacebookLoginInfo = (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse): userInfo is ReactFacebookLoginInfo =>
    (userInfo as ReactFacebookLoginInfo).accessToken !== undefined;

  const facebookLoginHandler = async (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
    if (!isReactFacebookLoginInfo(userInfo)) {
      console.error('handleFacebookResponseLogin', userInfo);
      setIsLoggedIn(false);
      return;
    }

    await facebookHandler(userInfo, 'login');

    setIsLoggedIn(true);
    logSettingsFacebookLoginConfigured({ facebook_login_action: FacebookLoginAction.EnableLoginWithFacebook });
  };

  useEffect(() => {
    if (userId) {
      const fetchFacebookStatus = async () => {
        const data = await getFacebookUploadStatus(userId);
        setFacebookStatus(data.contactUploads.facebook.status);
      };

      fetchFacebookStatus().catch(console.error);
    }
  }, []);

  useEffect(() => {
    if (facebookStatus === FacebookStatus.Done) {
      setIsLoggedIn(true);
      setIsSynced(true);
    } else {
      setIsLoggedIn(false);
      setIsSynced(false);
    }
  }, [facebookStatus]);

  const getFacebookStatusPoll = async () => {
    if (accessToken && userId) {
      const data = await getFacebookUploadStatus(userId);
      setFacebookStatus(data.contactUploads.facebook.status);

      if (facebookStatus === FacebookStatus.Failed) {
        setIsSyncing(false);
        setDelay(null);
      }
      if (facebookStatus === FacebookStatus.Done) {
        setIsSyncing(false);
        setIsSynced(true);
        setDelay(null);
      }
    }
  };

  useInterval(() => {
    getFacebookStatusPoll();
  }, delay);

  const stopSyncFriends = async () => {
    if (userId) {
      try {
        await stopSyncFacebookFriends(userId);
        setIsSynced(false);
        logCommunityContactsSynced({
          contacts_source: CommunityContactsSyncSource.Facebook,
          sync_location: CommunityContactsSyncLocation.FacebookSettings,
          is_disconnected: true,
          is_refreshed: false,
          num_contacts: -1, // -1 for "null" value
          num_registered_contacts: -1
        });
      } catch (err) {
        console.error('error when stop sync friends', err);
        setIsSynced(true);
      }
    }
  };

  const syncFacebookFriends = async (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
    if (!isReactFacebookLoginInfo(userInfo)) {
      console.error('handleFacebookResponseSyncFriends', userInfo);
      return;
    }

    setIsSyncing(true);
    setAccessToken(userInfo.accessToken);
    try {
      await uploadFacebookTokenToBelay(userInfo.accessToken, userId);
      logCommunityContactsSynced({
        contacts_source: CommunityContactsSyncSource.Facebook,
        sync_location: CommunityContactsSyncLocation.FacebookSettings,
        is_disconnected: false,
        is_refreshed: isSynced,
        num_contacts: -1, // -1 for "null" value
        num_registered_contacts: -1
      });
      setIsSyncing(false);
      setIsSynced(true);
    } catch (err) {
      console.error('error when syncing friends', err);
      setIsSynced(false);
    }
  };

  const openSettingsModal = () => {
    logSettingsFacebookPageViewed();
    setShowSettingsModal(true);
  };

  return (
    <CustomProvider>
      {showSettingsModal &&
        createPortal(
          <FacebookSettingsModal
            closeModal={closeModal}
            facebookLoginButton={
              <FacebookLogin
                disableMobileRedirect
                appId={facebookAppId}
                callback={facebookLoginHandler}
                scope="email,user_friends,user_location"
                render={(renderProps: { onClick: () => void }) => renderFacebookButton(true, isSynced, isSyncing, renderProps)}
              />
            }
            isLoggedIn={isLoggedIn || !!userFacebookId}
            handleDisconnect={handleUnlinkClick}
            facebookSyncFriendsButton={
              <FacebookLogin
                disableMobileRedirect
                appId={facebookAppId}
                callback={syncFacebookFriends}
                scope="user_friends"
                render={(renderProps: { onClick: () => void }) => renderFacebookButton(false, isSynced, isSyncing, renderProps)}
              />
            }
            isSynced={isSynced}
            handleUnsync={stopSyncFriends}
          />,
          document.getElementById('modalPortal')
        )}
      <div>
        <FacebookButton data-testid="facebookLoginButton" onClick={openSettingsModal}>
          <FormattedMessage defaultMessage="Facebook settings" />
        </FacebookButton>
      </div>
    </CustomProvider>
  );
};

export default FacebookSettings;
