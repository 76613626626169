import { ComponentProps, PropsWithChildren } from 'react';
import FooterWrapper from 'components/FooterWrapper';
import GlobalActions from 'components/GlobalActions';
import NavBar from 'components/NavBar';

type Props = {
  doNotRenderMainContentId?: boolean;
  wideFooter?: boolean;
} & ComponentProps<typeof NavBar>;

export default function ApplicationLayout({ children, doNotRenderMainContentId, wideFooter = false, ...navBarProps }: PropsWithChildren<Props>) {
  return (
    <>
      <GlobalActions />
      <div id="header">
        <NavBar {...navBarProps} />
      </div>
      {doNotRenderMainContentId ? children : <main id="main-content">{children}</main>}
      <FooterWrapper wideFooter={wideFooter} />
    </>
  );
}
